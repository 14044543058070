<template>
  <div class="list-item-container">
    <div class="list-item">
      <input
        type="number"
        v-model.number="editableQuantity"
        @blur="updateQuantity"
        min="0"
        class="quantity-input no-background"
      />
      <select
        v-model="editableUnit"
        @change="updateUnit"
        class="unit-dropdown no-background"
      >
        <option value="g">g</option>
        <option value="kg">kg</option>
        <option value="ml">ml</option>
        <option value="EL">EL</option>
        <option value="TL">TL</option>
        <option value="Pck">Pck</option>
        <option value="Stck">Stck</option>
      </select>

      <span
        v-if="!isEditing"
        @click="isEditing = true"
        :class="['title-span', expirationClass]"
        style="padding: 2px 4px; border-radius: 3px; font-size: 0.9rem;"
      >
        {{ editableTitle }}
      </span>
      <input
        v-else
        type="text"
        v-model="editableTitle"
        @blur="finishEditing"
        :class="['title-input', 'no-background']"
      />

      <div class="item-actions">
        <i
          class="bi bi-info-square-fill"
          :class="{ 'active-info': showInfo }"
          @click="toggleInfo"
        ></i>
        <i class="bi bi-trash2-fill" @click="deleteItem"></i>
      </div>
    </div>

    <!-- Tooltip that shows Ablaufdatum and Tags -->
    <div v-if="showInfo" class="tooltip-bubble">
      <p v-if="item.expiration && item.expiration !== 'N/A'">Ablaufdatum: {{ item.expiration }}</p>
      <p v-if="item.tags && item.tags.length > 0">Tags: {{ item.tags.join(', ') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      editableQuantity: this.item.quantity, // Editable quantity field
      editableUnit: this.item.quantityUnit, // Editable unit field
      editableTitle: this.item.title, // Editable title field
      showInfo: false, // Control the visibility of the tooltip
      isEditing: false, // Control the editing state for the title
    };
  },
  computed: {
    expirationClass() {
      const today = new Date();
      if (!this.item.expiration || this.item.expiration === 'N/A') {
        // If there's no expiration date, return an empty string so no class is applied
        return '';
      }
      const expirationDate = new Date(this.item.expiration);

      if (expirationDate < today) {
        return 'expired-item';
      }

      const diffDays = Math.ceil((expirationDate - today) / (1000 * 60 * 60 * 24));

      if (diffDays <= 4) {
        return 'critical-item';
      } else if (diffDays <= 14) {
        return 'warning-item';
      } else {
        return 'good-item';
      }
    },
  },
  methods: {
    toggleInfo() {
      this.showInfo = !this.showInfo; // Toggle the info display
    },
    async updateQuantity() {
    try {
      if (this.editableQuantity > 0) {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const oldTitle = this.item.title; 

        const itemData = { ...this.item, quantity: this.editableQuantity };
        await axios.put(`/api/storageItem/?userid=${username}&title=${this.item.title}`, itemData);

        // Emit the updated item to the parent component
        this.$emit('itemUpdated', { oldTitle, updatedItem: itemData });
      }
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  },
  async updateUnit() {
    try {
      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      const oldTitle = this.item.title; 

      const itemData = { ...this.item, quantityUnit: this.editableUnit };
      await axios.put(`/api/storageItem/?userid=${username}&title=${this.item.title}`, itemData);

      // Emit the updated item to the parent component
      this.$emit('itemUpdated', { oldTitle, updatedItem: itemData });
    } catch (error) {
      console.error('Error updating unit:', error);
    }
  },
  async updateTitle() {
    try {
      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      const oldTitle = this.item.title; 
      const itemData = { ...this.item, title: this.editableTitle };

      await axios.put(`/api/storageItem/?userid=${username}&title=${this.item.title}`, itemData);

      // Emit the updated item to the parent component
      this.$emit('itemUpdated', { oldTitle, updatedItem: itemData });
    } catch (error) {
      console.error('Error updating title:', error);
    }
  },
    async deleteItem() {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        var sanitizedItemTitle = this.item.title.replace(/&/g, '');
        sanitizedItemTitle.toLowerCase();

        await axios.delete(`/api/storageItem/?userid=${username}&title=${sanitizedItemTitle}`);
       
        this.$emit('itemDeleted', this.item.title); // Emit event to parent to handle UI update
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    },
    finishEditing() {
      this.isEditing = false;
      this.updateTitle();
    },
  },
};
</script>

<style scoped>
.list-item-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0 !important;
  padding: 0;
  flex-wrap: nowrap;
}

.quantity-input {
  width: 60px;
  text-align: left;
  border: 1px solid #ddd;
  background: none;
  padding: 5px;
  margin-right: 5px;
  flex-shrink: 0;
}

.unit-dropdown {
  border: 1px solid #ddd;
  background: none;
  margin-right: 10px;
  flex-shrink: 0;
}

.title-span {
  flex-grow: 1;
  text-align: left;
  white-space: normal;
  overflow-wrap: break-word;
  margin-right: 10px;
  padding: 5px;
  min-width: 120px;
  cursor: pointer;
  border-radius: 4px;
}

.title-input {
  flex-grow: 1;
  text-align: left;
  white-space: normal;
  overflow-wrap: break-word;
  margin-right: 10px;
  padding: 5px;
  min-width: 120px;
  border: 1px solid #ddd;
}

.item-actions {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}

.item-actions i {
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px;
}

.item-actions i:hover,
.active-info {
  color: #ac8d7e;
}

.tooltip-bubble {
  position: absolute;
  right: 40px;
  top: 40px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: max-content;
}

.tooltip-bubble p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.no-background {
  background-color: transparent;
  color: #000;
  appearance: none;
  border: none;
}

/* Updated styles for expiration states */
.expired-item {
  background-color: #f8d7da;
}

.critical-item {
  background-color: #f4cb9d; /* Dark orange */
}

.warning-item {
  background-color: rgb(245, 222, 180); /* Light orange */
}

.good-item {
  background-color: #d4edda;
}
</style>
