export const categories = {
  "Kühlschrank": [
    "milch", "käse", "eier", "butter", "joghurt", "sahne", "quark", "margarine",
    "frischkäse", "schinken", "salami", "wurst", "aufschnitt", "leberwurst",
    "hackfleisch", "hähnchenbrust", "pute", "speck", "lachs", "forelle", "makrele",
    "hüttenkäse", "buttermilch", "sauerrahm", "pudding", "kondensmilch", "tofu",
    "mozzarella", "gouda", "emmentaler", "brie", "camembert", "kefir", "schafskäse",
    "feta", "gurken", "tomaten", "paprika", "karotten", "radieschen", "rettich",
    "blattsalat", "rucola", "kohl", "porree", "zucchini", "aubergine", "sellerie",
    "petersilie", "dill", "basilikum", "frühlingszwiebeln", "zwiebeln", "knoblauch",
    "avocado", "zitronen", "limetten", "apfelsaft", "orangensaft", "leberkäse",
    "pesto", "babyspinat", "oliven", "kapern", "rinderfilet", "schlagsahne",
    "kefir", "parmesan", "kirschtomaten", "eiweiß", "lauch", "fenchel", "grüne bohnen",
    "butterkäse", "schmand", "joghurtbutter", "früchtejoghurt", "vanillepudding", "schokopudding",
    "flammkuchen", "yoghurt", "joghurt", "remoulade", "aioli", "sahne", "creme fraiche", "hummus", 
    "kochschinken", "geflügelwurst", "speisequark", "naturjoghurt", "obstsalat", "quarkspeise", 
    "milchreis", "schafjoghurt", "kinderpudding",
    "erdbeere", "spätzle", "würstchen", "geschnetzletes", "fleisch",
    "lasagne", "gnocchi", "ravioli", "tortellini","blätterteig", "strudelteig", "pfannkuchen",
    "schupfnudeln", "schweinebratebn", "aufschnitt"
  ],
  "Gefrierschrank": [
    "pizza", "gemüse", "eis", "tiefkühl", "pommes", "fischstäbchen", "hähnchennuggets", "nuggets", "fisch",
    "spinat", "kräuter","erbsen", "möhren", "mischgemüse",
     "pfannengemüse", "suppengemüse", "krabben", "seelachs",
    "lachs", "garnelen", "hummer", "muscheln", "waldfrüchte", "bratwurst", "frikadellen",
    "hackbällchen", "leberkäse", "schweinefilet", "hähnchenschenkel", "ente",
    "gans", "tintenfisch", "sushi", "chili-con-carne", "burger-patties", "wiener",
    "zwiebelsuppe", "rösti", "hashbrowns", "bagels", "eiskuchen", "kartoffelecken", "kroketten", "pommes",
    "zwiebelringe", "samosas",
    "frühlingsrollen", "fischfilet", "buttergemüse", "pastete",
    "rahmspinat","smoothie-mix","fisch",
    "pastamischung", "piroggen", "knödel", "fertiggericht", "fischstäbchen", "frozen-yoghurt",
    "vanilleeis", "schokoeis", "tiefkühlpommes", "chicken-wings", "tiefkühlbrot", "gemüsepfanne",
    "blumenkohlröschen"

  ],
  "Süßigkeiten": [
    "schokolade", "bonbon", "chips", "keks", "gummibärchen", "lakritz", "lutscher",
    "kaugummi", "schokoriegel", "pralinen", "knusperkugeln",
    "schokobons", "zuckerwatte", "marshmallows", "gummiwürmer", "schokoherzen",
    "lebkuchen", "pfefferminzbonbons", "lolly", "toffee", "karamellbonbons",
    "schokokekse", "waffeln", "schokocroissants", "nougat",
    "brausepulver", "zuckerstangen", "trüffel", "brownies", "muffins",
    "fruchtgummis", "schokolinsen", "dragées", "peanutbuttercups", "weingummi",
    "bounty", "snickers", "mars", "m&m", "twix", "oreo", "nimm2", "milka",
    "rittersport", "kitkat", "maltesers", "lion", "ferrero", "mon cherie",
    "hanuta", "duplo", "kinder", "knoppers", "mürbeteigkekse", "salted caramel",
    "zotter", "schokobananen", "pfeffernüsse", "honeycomb", "happydent",
    "mentos", "schokostreusel", "zimtsterne", "spekulatius", "butterkekse",
    "haselnusskrokant", "mandelgebäck", "windbeutel", "schokomousse",
    "eiskonfekt", "baiser", "schwarzbrotchips", "bretzel", "choco-crossies",
    "kakaobutter", "mürbteigplätzchen", "zimtschnecken", "lakritzschnecken",
    "minzschokolade", "kokosmakronen", "amerikaner", "fruchtleder", "popcorn", "schokoerdnüsse",
    "schokorosinen", "brausewürfel", "weingummi", "nugatpralinen",
    "toffifee"
  ],
  "Vorratsschrank": [
    "nudeln", "reis", "linsen", "bohnen", "tomatensauce", "konserven", "thunfisch",
    "oliven", "eingelegte gurken", "essiggurken", "eingelegte paprika", "champignons",
    "mais", "mandeln", "haselnüsse", "rosinen",
    "getrocknete aprikosen", "getrocknete tomaten", "sultaninen", "trockenerbsen",
    "tomatenmark", "senf", "ketchup", "honig", "marmelade", "erdnussbutter",
    "olivenöl", "sonnenblumenöl", "mehl", "zucker", "backpulver", "vanillezucker",
    "kakao", "geriebene kokosnuss", "kokosmilch", "speisestärke", "nudelsuppe",
    "linsensuppe", "erbsensuppe", "eintopf", "fleischkonserven", "bonduelle mais",
    "bonduelle erbsen", "bonduelle bohnen", "ravioli", "fertiggerichte",
    "fischkonserven", "haferflocken", "cornflakes", "müsli", "backhefe", "trockenhefe",
    "lebensmittelfarbe", "fischfond", "gemüsebrühe", "fleischbrühe", "paprikapulver",
    "currypulver", "kurkuma", "currysoße", "sambal oelek", "ovomaltine", "nutella", "gewürzgurken",
    "tortellini", "balsamico", "sojasoße", "reiswaffeln", "erdnüsse gesalzen", "haferdrink", "kokosöl",
    "agavendicksaft", "tahin", "gemahlene nüsse",
    "baguette", "brötchen", "gewürz", "citronat", "zitronat", "tortenguss", "sahnesteif",
    "dürum", "glas", "dose", "5 minuten", "griesbrei", "pulver", "paradies creme", "topping",
    "carramel", "karamell", "ciabatta", "garant", "hefe", "fix", "maggi", "cornichons",
    "snibbels", "brot", "granola", "Cornflakes", "Kornflakes", "müsli", "makkaroni", "spaghetti"
  ],
  "Sonstiges": [
    "brot", "knäckebrot", "zwieback", "croutons", "cracker", "salz", "pfeffer",
    "paprikapulver", "chilipulver", "kümmel", "zimt", "nelken", "muskatnuss",
    "rosmarin", "thymian", "baguette", "brötchen", "butterschmalz", "essig",
    "sojasauce", "mayonnaise", "zwiebelpulver", "knoblauchpulver", "backkakao",
    "kräuter der provence", "zimtstangen", "kardamom", "oregano", "geräuchertes salz", 
    "kokosblütenzucker", "maisstärke", "vitalbrot"
  ],
  "ObstundGemüse": [
    "äpfel", "apfel", "banane", "orange", "zitrone", "birne", "traube", "mandarine", 
    "ananas", "kiwis", "melonen", "pfirsiche", "nektarine", "pflaume", "granatäpfel", "kirschen", "karotten",
    "tomaten", "gurken", "paprika", "brokkoli", "zucchini", "aubergine", "blumenkohl",
    "spinat", "sellerie", "kohl", "porree", "zwiebeln", "knoblauch", "kartoffeln",
    "radieschen", "rettich", "fenchel", "grüne bohnen", "erbsen", "mais",
    "kürbis", "champignons", "salat", "rucola", "petersilie", "dill", "basilikum",
    "koriander", "minze", "blaubeeren", "himbeeren", "mangos", "datteln", "granatapfelkerne",
    "esskastanien", "passionsfrucht", "kokosnuss", "rosenkohl", "chicorée", "rote bete",
    "topinambur", "physalis", "okraschoten",
    "brokkoli",  "wirsing", "mango", "ananas"
  ],
  "Haushalt": [
    "waschmittel", "waschmaschine", "weichspüler", "tabs", "spülmittel", "abwaschbürste", 
    "spülschaum", "müllbeutel",
    "putztücher", "putzmittel", "klopapier", "küchenrolle", "staubsaugerbeutel",
    "geschirrspültabs", "lufterfrischer", "allzweckreiniger", "fensterreiniger",
    "badezimmerreiniger", "bodenreiniger", "wc-reiniger", "batterien", "kerzen",
    "feuerzeug", "streichhölzer", "alufolie", "frischhaltefolie", "backpapier",
    "gefrierbeutel", "staubtücher", "mikrofasertücher", "bürste", "schrubber",
    "fensterabzieher", "eimer", "handschuhe", "wischmop", "kehrblech", "handfeger",
    "staubwedel", "putzeimer", "lappen", "polsterreiniger", "fleckentferner",
    "wc-steine", "lufterfrischer", "insektenmittel", "handseife", "desinfektionsmittel",
    "gummihandschuhe", "schwämme", "spülbürste", "entkalker", "abflussreiniger"
  ],
  "Getränke": [
    "wasser", "cola", "fanta", "sprite", "bier", "wein", "säfte", "orangensaft", "apfelsaft",
    "mineralwasser", "tonic", "gin", "whiskey", "rum", "vodka", "limonade", "energy drink",
    "kakao", "milch", "tee", "kaffee", "espresso", "latte", "mocha", "eiskaffee",
    "prosecco", "sekt", "cidre", "glühwein", "bloody mary", "gin tonic", "martini",
    "radler", "pils", "hefeweizen", "alkoholfreies bier", "cola light", "eistee",
    "smoothie", "buttermilch", "kefir", "sportgetränk", "kokoswasser", "pepsi",
    "red bull", "redbull", "sirup"
  ]
}
