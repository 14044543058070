<template>
    <div class="add-list-item-container">
      <form @submit.prevent="addListItem" class="form-inline d-flex justify-content-center align-items-center">
        <input
          type="number"
          v-model="quantity"
          id="quantity"
          class="form-control quantity-input no-background mr-2"
          placeholder="Menge"
        />
        <select v-model="quantityUnit" id="quantityUnit" class="form-control unit-select mr-2 no-background">
          <option value="g">g</option>
          <option value="kg">kg</option>
          <option value="l">l</option>
          <option value="ml">ml</option>
          <option value="EL">EL</option>
          <option value="TL">TL</option>
          <option value="Pck">Pck</option>
          <option value="Stck">Stck</option>
          <option value="Prise">Prise/n</option>
          <option value="Scheibe/n">Scheiben</option>
        </select>
  
        <input
          type="text"
          v-model="name"
          id="name"
          class="form-control name-input mr-2 no-background"
          placeholder="Artikelname"
          required
          @input="checkListItemTitle"
        />
        <button type="submit" class="btn btn-custom" :disabled="titleExists">
          <i class="bi bi-check-circle-fill"></i>
        </button>
      </form>
      <div v-if="titleExists" class="text-danger centered mt-2">
        <i class="bi bi-x-circle-fill"></i> Dieses Artikel existiert bereits
      </div>
      <div v-if="titleExists === false && name" class="text-success centered mt-2">
        <i class="bi bi-check-circle-fill"></i> Artikel ist noch nicht in der Liste
      </div>
    </div>
  </template>
  
<script>
import axios from 'axios';
import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';

export default {
data() {
    return {
    name: '',
    quantity: 1,
    quantityUnit: 'Pck',
    titleExists: null, // Added to track if the title exists
    };
},
methods: {
    async addListItem() {
    try {
        const token = getCookie('token');
        if (!token) {
        throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const listItemData = {
        title: this.name,
        quantity: this.quantity,
        quantityUnit: this.quantityUnit,
        };

        const response = await axios.post(`/api/listItem/?id=${username}`, listItemData);
        if (response.status === 200) {
        this.$emit('itemAdded', listItemData);
        this.resetForm();
        }
    } catch (error) {
        console.error('Error adding list item:', error);
    }
    },
    resetForm() {
    this.name = '';
    this.quantity = 1;
    this.quantityUnit = 'Pck';
    this.titleExists = null; // Reset titleExists
    },
    async checkListItemTitle() {
    const token = getCookie('token');
    if (!token) {
        throw new Error('Token not found');
    }
    const decodedToken = jwtDecode(token);
    const username = decodedToken.username;

    const titleToCheck = this.name.toLowerCase().trim();

    if (titleToCheck === '') {
        this.titleExists = null;
        return;
    }

    try {
        const response = await axios.get(`/api/checkListTitle?userid=${username}&title=${encodeURIComponent(titleToCheck)}`);
        this.titleExists = response.data.exists;
    } catch (error) {
        console.error('Error checking list item title:', error);
        this.titleExists = null;
    }
    }
},
};
</script>

<style scoped>
.add-list-item-container {
  margin-bottom: 20px;
}

.form-inline {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the form */
  max-width: 600px;
  width: 100%;
  margin: 0 auto; /* Center the entire container */
}

.quantity-input {
  max-width: 80px; /* Reduce the width for quantity */
}

.unit-select {
  max-width: 100px; /* Reduce the width for unit */
}

.name-input {
  flex: 2; /* Make the name input take more space */
}

.form-control {
  margin-right: 10px;
}

.form-control:last-child {
  margin-right: 0;
}

.btn-custom {
  background-color: #ac8d7e;
  border: none;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-custom i {
  font-size: 18px;
  color: white;
}

.no-background {
  background-color: transparent;
  color: #000;
  appearance: none;
}

.centered {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
}
</style>