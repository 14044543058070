<template>
    <div class="d-flex justify-content-center align-items-center">
      <div class="list-item-container">
        <div class="list-item">
          <input 
            type="number" 
            v-model.number="editableQuantity" 
            @blur="updateQuantity" 
            min="0" 
            class="quantity-input no-background"
          />
          <select v-model="editableUnit" @change="updateUnit" class="unit-dropdown no-background">
            <option value="g">g</option>
            <option value="kg">kg</option>
            <option value="ml">ml</option>
            <option value="EL">EL</option>
            <option value="TL">TL</option>
            <option value="Pck">Pck</option>
            <option value="Stck">Stck</option>
          </select>
          <input 
            type="text" 
            v-model="editableTitle" 
            @blur="updateTitle" 
            class="title-input no-background"
          />
          <div class="item-actions">
            <i class="bi bi-box-seam-fill" @click="moveToStorage"></i>
            <i class="bi bi-check-square-fill" @click="deleteItem"></i>
          </div>
        </div>
      </div>
      <!-- Modal Component -->
      <ListModal
        v-if="showModal"
        :showModal="showModal"
        :mismatchedIngredients="modalData"
        @close-modal="closeModal"
        @confirm-changes="addToStorageFromModal"
      />
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { getCookie } from '../cookieUtils';
  import { jwtDecode } from 'jwt-decode';
  import ListModal from './ListModal.vue'; // Import the ListModal component
  
  export default {
    components: {
      ListModal,
    },
    props: {
      item: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },
    data() {
      return {
        showModal: false,
        modalData: [], // Data to be passed to the modal
        editableQuantity: this.item.quantity,
        editableUnit: this.item.quantityUnit,
        editableTitle: this.item.title,
      };
    },
    watch: {
      item: {
        handler(newItem) {
          this.editableQuantity = newItem.quantity;
          this.editableUnit = newItem.quantityUnit;
          this.editableTitle = newItem.title;
        },
        deep: true,
      },
    },
    methods: {
      async updateQuantity() {
        try {
          const token = getCookie('token');
          const decodedToken = jwtDecode(token);
          const username = decodedToken.username;

          const updatedItem = { ...this.item, quantity: this.editableQuantity }; // Include new quantity

          await axios.put(`/api/listItem/?userid=${username}&title=${this.item.title}`, updatedItem);

          this.$emit('itemUpdated', { oldTitle: this.item.title, updatedItem });
        } catch (error) {
          console.error('Error updating quantity:', error);
        }
      },
      async updateUnit() {
        try {
          const token = getCookie('token');
          const decodedToken = jwtDecode(token);
          const username = decodedToken.username;

          const updatedItem = { ...this.item, quantityUnit: this.editableUnit }; // Include new unit

          await axios.put(`/api/listItem/?userid=${username}&title=${this.item.title}`, updatedItem);

          this.$emit('itemUpdated', { oldTitle: this.item.title, updatedItem });
        } catch (error) {
          console.error('Error updating unit:', error);
        }
      },
    async updateTitle() {
      try {
        const token = getCookie('token');
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const updatedItem = { ...this.item, title: this.editableTitle }; // Include the new title

        await axios.put(`/api/listItem/?userid=${username}&title=${this.item.title}`, updatedItem); // Use old title in the URL

        this.$emit('itemUpdated', { oldTitle: this.item.title, updatedItem }); // Emit both old and updated item
      } catch (error) {
        console.error('Error updating title:', error);
      }
    },
      async moveToStorage() {
        try {
          const token = getCookie('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const decodedToken = jwtDecode(token);
          const username = decodedToken.username;
  
          // Fetch storage items
          const response = await axios.get(`/api/storageItem/?id=${username}`);
          const storageItems = response.data;
  
          // Check if the item already exists in storage
          const existingItem = storageItems.find(
            (item) => item.title.toLowerCase() === this.item.title.toLowerCase()
            );
  
          if (existingItem) {
            if (existingItem.quantityUnit === this.item.quantityUnit) {
              const updatedItem = {
                ...existingItem,
                quantity: existingItem.quantity + this.item.quantity,
              };
              
              await axios.put(`/api/storageItem/?userid=${username}&title=${updatedItem.title}`, updatedItem);
              await this.deleteItem();
            } else {
              this.modalData = [
                {
                  ingredient: {
                    quantity: this.item.quantity,
                    quantityUnit: existingItem.quantityUnit,
                    name: this.item.title,
                  },
                },
              ];
              this.showModal = true;
            }
            this.$emit('toStorage', this.item);
          } else {
            const newItem = {
                title: this.item.title,
                quantity: this.item.quantity,
                quantityUnit: this.item.quantityUnit,
            };
            await axios.post(`/api/storageItem/?id=${username}`, newItem);
            await this.deleteItem();

            // Emit the event with the new item
            this.$emit('toStorage', newItem);
          }
        } catch (error) {
          console.error('Error moving item to storage:', error);
        }
      },
      async addToStorageFromModal(mismatchedIngredients) {
        try {
            const token = getCookie('token');
            const decodedToken = jwtDecode(token);
            const username = decodedToken.username;
    
            const ingredient = mismatchedIngredients[0].ingredient;
    
            const response = await axios.get(`/api/storageItem/?id=${username}`);
            const storageItems = response.data;
            
            const existingItem = storageItems.find(
                (item) => item.title.toLowerCase() === this.item.title.toLowerCase()
                );

            const updatedItem = {
                title: ingredient.name,
                quantity: existingItem ? existingItem.quantity + parseFloat(ingredient.quantity) : parseFloat(ingredient.quantity),
                quantityUnit: existingItem.quantityUnit,
            };

          await axios.put(`/api/storageItem/?userid=${username}&title=${updatedItem.title}`, updatedItem);
          await this.deleteItem();
          this.$emit('toStorage', updatedItem);

          this.closeModal();
        } catch (error) {
          console.error('Error adding item to storage from modal:', error);
        }
      },
      closeModal() {
        this.showModal = false;
      },
      async deleteItem() {
        try {
          const token = getCookie('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const decodedToken = jwtDecode(token);
          const username = decodedToken.username;
  
          await axios.delete(`/api/listItem/?userid=${username}&title=${this.item.title}`);
          this.$emit('itemDeleted', this.item.title);
        } catch (error) {
          console.error('Error deleting list item:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .list-item-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0 !important;
  padding: 0;
  flex-wrap: nowrap; /* This keeps the elements on one line */
}

.quantity-input {
  width: 60px; /* Adjust width for responsive design */
  text-align: left;
  border: 1px solid #ddd;
  background: none;
  padding: 5px;
  margin-right: 5px;
  flex-shrink: 0; /* Prevent shrinking of input field */
}

.unit-dropdown {
  border: 1px solid #ddd;
  background: none;
  margin-right: 10px;
  flex-shrink: 0; /* Prevent shrinking of dropdown */
}

.title-input {
  flex-grow: 1; /* Allow title to take the remaining space */
  text-align: left;
  white-space: normal; /* Allow text to wrap to the next line */
  overflow-wrap: break-word; /* Break words if they are too long */
  margin-right: 10px;
  padding: 5px;
  min-width: 120px; /* Ensure minimum width for the title input */
}

.item-actions {
  display: flex;
  align-items: center; /* Center icons vertically with the text */
  margin-left: auto; /* Align actions to the right */
  flex-shrink: 0; /* Prevent shrinking of actions */
}

.item-actions i {
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px;
}

.item-actions i:hover,
.active-info {
  color: #ac8d7e; /* Brown color for hover and active state */
}

.no-background {
  background-color: transparent;
  color: #000;
  appearance: none;
  border: none;
}

/* Tooltip for expiration and tags, optional but can be adjusted */
.tooltip-bubble {
  position: absolute;
  right: 40px;
  top: 40px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: max-content;
}

.tooltip-bubble p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

  </style>