<template>
  <div class="filter-container">
    <div class="d-flex align-items-center">
      <input
        type="text"
        class="form-control search-input no-background"
        placeholder="Suche..."
        v-model="searchQuery"
        @input="emitSearchQuery"
      />

      <button class="btn btn-brown ml-2 " @click="navigateToAddRecipe">
        <i class="bi bi-plus"></i>
      </button>
    </div>

    <div class="filter-buttons">
      <button
        v-for="tag in combinedTags"
        :key="tag"
        @click="toggleFilter(tag)"
        :class="['btn', 'btn-outline-custom', { 'active': selectedTags.includes(tag) }]"
      >
        {{ tag }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: Array,
    methods: Array
  },
  data() {
    return {
      selectedTags: [],
      searchQuery: ''
    }
  },
  computed: {
    combinedTags() {
      return [...this.tags, ...this.methods];
    }
  },
  methods: {
    toggleFilter(tag) {
      const index = this.selectedTags.indexOf(tag);
      if (index >= 0) {
        this.selectedTags.splice(index, 1);
      } else {
        this.selectedTags.push(tag);
      }
      this.$emit('filter', this.selectedTags);
    },
    emitSearchQuery() {
      const searchTerms = this.searchQuery.split(',')
        .map(term => term.trim())
        .filter(term => term);
      this.$emit('search', searchTerms);
    },
    navigateToAddRecipe() {
      this.$router.push('/add-recipe');
    }
  }
}
</script>

<style scoped>
.search-input {
  margin-top: 5px;
  border-color: #ac8d7e;
  color: #000;
}
.search-input:focus {
  border-color: #ac8d7e;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
/*.btn-outline-custom{
  color: black !important;
}*/
.btn-outline-custom.active {
  background-color: #ac8d7e;
  color: white;
}
.filter-buttons {
  margin-top: 10px;
}
.filter-container {
  margin-bottom: 20px;
}
.btn-brown {
  background-color: #ac8d7e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 5px;
}
.btn-brown:hover {
  background-color: #ac8d7e;
}

.add-recipe-btn {
  margin-left: auto; /* Aligns the button to the right */
}

.no-background {
  background-color: transparent;
  color: #000;
  appearance: none;
}
</style>