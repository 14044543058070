// src/store/auth.js
import { ref } from 'vue';
import axios from 'axios';
import { setCookie, getCookie, deleteCookie } from '../cookieUtils';

const isAuthenticated = ref(!!getCookie('token'));


const getValidatedUser = async (username, password) => {
  try {
    const response = await axios.post('/api/login', { username, password });
    const userId = response.data.username;
    return userId;
  } catch (error) {
    console.error('Error checking consent:', error);
    return false;
  }
};

const login = async (username, password) => {
  try {
    const response = await axios.post('/api/login', { username, password });
    const user = response.data.user; // Assuming the response returns user data
    setCookie('token', response.data.token, 7); // Set token cookie with 7 days expiry
    isAuthenticated.value = true;
    return user; // Return user object containing the true username
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

const register = async (username, password, secret, verificationCode) => {
  try {
    const response = await axios.post('/api/user', {
      username: username,
      password: password,
      secret: secret,
      verificationCode: verificationCode
    });
    setCookie('token', response.data.token, 7); // Set token cookie with 7 days expiry
    isAuthenticated.value = true;
  } catch (error) {
    console.error('Registration failed::', error);
    throw error;
  }
};

const logout = (router) => {
  deleteCookie('token');
  isAuthenticated.value = false;
  router.push({ path: '/', query: { view: 'recipes' } });
};

const checkConsent = async (username) => {
  try {
    const response = await axios.get(`/api/consent?id=${username}`);
    return response.data.agb && response.data.datenschutzerklaerung;
  } catch (error) {
    console.error('Error checking consent:', error);
    return false;
  }
};

const acceptConsent = async (username) => {
  try {
    const consentData = {
      agb: true,
      datenschutzerklaerung: true,
    };
    await axios.post(`/api/consent?id=${username}`, consentData);
  } catch (error) {
    console.error('Error accepting consent:', error);
    throw error;
  }
};

export const useAuth = () => {
  return {
    isAuthenticated,
    login,
    logout, 
    register,
    checkConsent,
    getValidatedUser,
    acceptConsent
  };
};