<template>
    <div>
      <div v-if="showBanner" class="cookie-banner">
        <div class="cookie-content">
          <h3>Zustimmung</h3>
          <p>
            Diese Webseite verwendet Cookies.
            Wir verwenden notwendige Cookies, um die grundlegenden Funktionen der Website sicherzustellen.
            Darüber hinaus können wir Cookies verwenden, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anzubieten und die Zugriffe auf unsere Website zu analysieren.
            Sie geben Ihre Zustimmung, dass wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weitergeben dürfen.
            Unsere Partner können diese Informationen möglicherweise mit weiteren Daten zusammenführen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.
          </p>
          <div class="cookie-options">
            <div>
              <label>
                <input type="checkbox" v-model="preferences"> Präferenzen
              </label>
            </div>
            <div>
              <label>
                <input type="checkbox" v-model="statistics"> Statistiken
              </label>
            </div>
            <div>
              <label>
                <input type="checkbox" v-model="marketing"> Marketing
              </label>
            </div>
          </div>
          <div class="cookie-buttons">
            <button @click="declineCookies" class="btn decline">Nur funktionale Cookies</button>
            <button @click="allowSelection" class="btn allow-selection">Auswahl erlauben</button>
            <button @click="allowAll" class="btn allow-all">Alle zulassen</button>
          </div>
        </div>
      </div>
      <div v-else class="cookie-icon" @click="toggleBanner">
        <!--<i class="bi bi-cookie"></i>-->
        <span class="cookie-text">Cookies verwalten</span>
      </div>
    </div>
  </template>
  
  <script>
  import { setCookie, getCookie } from '../cookieUtils';
  
  export default {
    data() {
      return {
        showBanner: true,
        preferences: false,
        statistics: false,
        marketing: false,
        showIcon: false,
      };
    },
    mounted() {
      this.checkCookieConsent();
    },
    methods: {
      checkCookieConsent() {
        const consent = getCookie('cookieConsent');
        if (consent) {
          this.showBanner = false;
          this.showIcon = true;
        }
      },
      declineCookies() {
        const consent = {
          functionality: true,
          preferences: false,
          statistics: false,
          marketing: false,
        };
        this.setCookieConsent(JSON.stringify(consent));
        this.hideBanner();
      },
      allowSelection() {
        const consent = {
          functionality: true,
          preferences: this.preferences,
          statistics: this.statistics,
          marketing: this.marketing,
        };
        this.setCookieConsent(JSON.stringify(consent));
        this.hideBanner();
      },
      allowAll() {
        const consent = {
          functionality: true,
          preferences: true,
          statistics: true,
          marketing: true,
        };
        this.setCookieConsent(JSON.stringify(consent));
        this.hideBanner();
      },
      setCookieConsent(value) {
        setCookie('cookieConsent', value, 365);
      },
      hideBanner() {
        this.showBanner = false;
        this.showIcon = true;
      },
      toggleBanner() {
        this.showBanner = !this.showBanner;
      },
    },
  };
  </script>
  
  <style scoped>
  .cookie-banner {
    position: fixed; 
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    border-top: 1px solid #ac8d7e;
    padding: 20px;
    z-index: 1000;
  }
  
  .cookie-content {
    max-width: 800px;
    margin: auto;
    text-align: center;
  }
  
  .cookie-options {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .cookie-options div {
    flex: 1;
  }
  
  .cookie-buttons {
    display: flex;
    justify-content: space-around;
  }
  
  .cookie-buttons .btn {
    background-color: #ac8d7e;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin: 5px;
  }
  
  .cookie-buttons .btn.decline {
    background-color: #dc3545;
  }
  
  /* FIXED ICON
  /*ENABLE IF COOKIE SHOULD BE FIXED LEFT BOTTOM - MOVE FROM FOOTER OUR IN APP.VUE and eable css cookie iecin below
   .cookie-icon {
    position: fixed;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 1001;
    font-size: 30px;
  }*/

  .cookie-icon {
    cursor: pointer;
    text-decoration: underline;

  }
  </style>