<template>
  <div class="forgot-password-container">
    <h2>Passwort vergessen</h2>
    <form @submit.prevent="verifyAuthCode" v-if="!authVerified" class="form">
      <div class="form-group">
        <label for="username">Nutzername</label>
        <input v-model="username" type="text" id="username" class="form-control" placeholder="Username" required />
      </div>
      <div class="form-group">
        <label for="authCode">Authenticator Code</label>
        <input v-model="authCode" type="text" id="authCode" class="form-control" placeholder="Authenticator Code" required />
      </div>
      <button type="submit" class="btn btn-reset">Weiter</button>
    </form>
    <form @submit.prevent="resetPassword" v-if="authVerified" class="form">
      <div class="form-group">
        <label for="newPassword">Neues Passwort</label>
        <input type="password" id="newPassword" v-model="newPassword" required class="form-control" />
      </div>
      <div class="form-group">
        <label for="confirmPassword">Neues Passwort bestätigen</label>
        <input type="password" id="confirmPassword" v-model="confirmPassword" required class="form-control" />
      </div>
      <button type="submit" class="btn btn-reset">Speichern</button>
    </form>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'ForgotPassword',
  setup() {
    let username = ref('');
    let authCode = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const authVerified = ref(false);
    const message = ref('');
    const router = useRouter();

    const verifyAuthCode = async () => {
      try {
       
        const clientTime = new Date().toISOString(); // Capture client time in ISO format
        const response = await axios.post('/api/verifyAuthCode', { username: username.value, authCode: authCode.value, clientTime });
        if (response.status === 200) {
          authVerified.value = true;
          message.value = 'Authenticator code verified. Please enter your new password.';
        }
      } catch (error) {
        console.error('Error verifying authenticator code:', error);
        message.value = 'Invalid authenticator code.';
      }
    };

    const resetPassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        message.value = 'Passwörter sind nicht gleich.';
        return;
      }

      try {
        await axios.put('/api/password', { username: username.value, newPassword: newPassword.value });
        message.value = 'Password erfolgreich zurückgesetzt';
        router.push('/');
        //router.go(0);
      } catch (error) {
        console.error('Error resetting password:', error);
        message.value = 'Password reset failed.';
      }
    };

    return { username, authCode, newPassword, confirmPassword, authVerified, message, verifyAuthCode, resetPassword };
  }
};
</script>

<style scoped>
.forgot-password-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  color: #343a40;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #495057;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.btn-reset {
  width: 100%;
  padding: 10px;
  background-color: #ac8d7e;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-reset:hover {
  background-color: #c08c5a;
}

.btn-reset:disabled {
  background-color: #e0a899;
  cursor: not-allowed;
}
</style>