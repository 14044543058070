<template>
  <div class="container">
    <div v-if="isAuthenticated" class="view-buttons">
      <div class="tab-navigation">
        <button @click="handleViewChange('recipes')" :class="{ active: currentView === 'recipes' }">Rezepte</button>
        <button @click="handleViewChange('storage')" :class="{ active: currentView === 'storage' }">Vorrat</button>
        <button @click="handleViewChange('dishes')" :class="{ active: currentView === 'dishes' }">Gerichte</button>
        <button @click="handleViewChange('list')" :class="{ active: currentView === 'list' }">
          <i class="bi bi-journal-check"></i>
        </button>
      </div>
    </div>

    <div v-if="currentView === 'storage'" class="badge-container">
      <span 
        class="badge expired-badge" 
        :class="{ 'selected-badge': selectedExpirationFilters.includes('expired') }"
        @click="handleBadgeFilter('expired')"
      >Abgelaufen</span>
      <span 
        class="badge critical-badge" 
        :class="{ 'selected-badge': selectedExpirationFilters.includes('critical') }"
        @click="handleBadgeFilter('critical')"
      >&lt; 4 Tage</span>
      <span 
        class="badge warning-badge" 
        :class="{ 'selected-badge': selectedExpirationFilters.includes('warning') }"
        @click="handleBadgeFilter('warning')"
      >&lt; 2 Wochen</span>
      <span 
        class="badge good-badge" 
        :class="{ 'selected-badge': selectedExpirationFilters.includes('good') }"
        @click="handleBadgeFilter('good')"
      >&gt; 2 Wochen</span>
    </div>

    <div v-if="currentView === 'dishes'" class="badge-container">
      <span class="badge enough-badge">vorrätig</span>
      <span class="badge different-unit-badge">andere Einheit</span>
      <span class="badge not-enough-badge">zu wenig</span>
    </div>


    <FilterComponent
      v-if="currentView === 'recipes'"
      :tags="tags"
      :methods="methods"
      @filter="applyFilter"
      @search="applySearch"
    />
   
    <StorageFilterComponent
      v-if="currentView === 'storage'"
      :tags="tags"
      @filter="applyFilter"
      @search="applySearch"
      @sort="applySort"
      @show-add-storage-modal="showAddStorageModal" 
    />

    <div v-if="currentView === 'storage'">
      <AddStorageItem 
        v-if="showAddStorageModalFlag" 
        @close-modal="showAddStorageModalFlag = false" 
        @itemAdded="handleStorageItemAdded" 
      />
    </div>

    <DishesFilterComponent
      v-if="currentView === 'dishes'"
      @show-add-dish-modal="showAddDishModal"
      @filter="applyFilter"
      @search="applySearch"
      @sort="applySort"
    />

    <div v-if="currentView === 'dishes'">
      <AddDishesItem v-if="showAddDishModalFlag" 
        @close-modal="showAddDishModalFlag = false" 
        @dish-added="addDish" 
        @dish-updated="updateDish" 
        :dishData="editableDishData" />
    </div>
    <div class="row" v-if="currentView === 'recipes'">
      <div class="col-12 col-md-6 col-lg-4 mb-3" v-for="item in paginatedItems" :key="item.id">
        <ItemComponent 
          v-if="currentView === 'recipes'" 
          :item="item"
          @recipeAdded="handleRecipeItemAdded"
          @toDishes="toDishes"
          @removeDishes="removeDishes"
          :dishesItems="dishesItems"
        />

      </div>
    </div>
    <div v-if="currentView === 'storage'">
      <div v-for="(items, category) in sortedStorageItems" :key="category">
        <div class="category-header">
          <span>{{ category }}</span>
        </div>
        <div v-for="item in items" :key="item.title">
          <StorageItemComponent 
            :item="item"
            @itemUpdated="updateItem"
            @itemDeleted="removeItem"
          />
        </div>
      </div>
    </div>
    <!--<div class="col-12" v-for="item in paginatedItems" :key="item.id">
      <StorageItemComponent 
        v-if="currentView === 'storage'" 
        :item="item"
        @itemUpdated="updateItem"
        @itemDeleted="removeItem"
      /> 
    </div>
-->
    <div v-if="currentView === 'dishes'">
      <DishesItemComponent 
        :dish="dish"
        :dishesItems="paginatedItems "
        :storageItems="storageItems"
        @update:dishesItems="updateDishes"
        @edit-dish="editDish"
        @dishDeleted="removeItem"
        @toList="updateList"
      />
    </div>

    <div v-if="currentView === 'list'">
      <AddListItem @itemAdded="handleListItemAdded" />

      <div v-for="(items, category) in sortedItems" :key="category">
        <div class="category-header">
          <span>{{ category }}</span>
        </div>
        <div v-for="item in items" :key="item.title">
          <ListItemComponent
            :item="item"
            @itemUpdated="updateListItem"
            @itemDeleted="removeListItem"
            @toStorage="updateStorage"
          />
        </div>
      </div>

      <!--<div v-for="(item, index) in listItems" :key="index">
        <ListItemComponent
          :item="item"
          @itemUpdated="updateListItem"
          @itemDeleted="removeListItem"
          @toStorage="updateStorage"
        />
      </div>-->
    </div>
        
    <PaginationComponent
      v-if="totalPages > 1" 
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-changed="changePage"
    />
    
  </div>
</template>

<script>
import axios from 'axios';
import cache from '../store/cache.js';

import ItemComponent from '../components/ItemComponent.vue';
import FilterComponent from '../components/FilterComponent.vue';
import PaginationComponent from '../components/PaginationComponent.vue';
import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';

import StorageItemComponent from '../components/StorageItemComponent.vue';
import StorageFilterComponent from '../components/StorageFilterComponent.vue';
import AddStorageItem from './AddStorageItem.vue';

import DishesItemComponent from '../components/DishesItemComponent.vue';
import DishesFilterComponent from '../components/DishesFilterComponent.vue';
import AddDishesItem from './AddDishesItem.vue';

import ListItemComponent from '../components/ListItemComponent.vue';
import AddListItem from './AddListItem.vue';
import { useAuth } from '../store/auth';

import { categories } from '../store/categories.js';

export default {
  components: {
    ItemComponent,
    FilterComponent,
    PaginationComponent,
    StorageItemComponent,
    StorageFilterComponent,
    DishesItemComponent,
    DishesFilterComponent,
    AddDishesItem,
    AddStorageItem,
    ListItemComponent,
    AddListItem
  },
  setup() {
    const { isAuthenticated } = useAuth();
    
    return {
      isAuthenticated
    };
  },
  data() {
    return {
      items: [],
      filteredItems: [],
      storageItems: [],
      dishesItems: [],
      listItems: [],
      tags: ['Auflauf', 'Salat', 'Gemüse', 'Obst', 'Hähnchen', 'Schwein', 'Vegetarisch', 'Vegan', 'Schnell'],
      methods: ['Ofen', 'Heißluftfritteuse', 'Herd'],
      currentPage: 1,
      itemsPerPage: 8,
      searchQueries: [],
      selectedTags: [],
      currentView: 'recipes',
      isStorageView: false,
      selectedSort: 'newest',
      showAddDishModalFlag: false,
      showAddStorageModalFlag: false,
      editableDishData: {},
      categories,
      sortedItems: {}, 
      sortedStorageItems: {},
      selectedExpirationFilters: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Use a regular expression to match both '/add-recipe' and '/edit-recipe' with or without an ID
      if (/^\/(add-recipe|edit-recipe|mine)(\/\d+)?$/.test(from.path)) {
        vm.currentView = "recipes";
        cache.recipes = null; // Clear the cache
        vm.loadRecipes(); // Load recipes after clearing the cache
      }
    });
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },

  },
  watch: {
    filteredItems() {
      // Ensure currentPage is within valid range after filtering
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
      }
    },
    currentView(newView, oldView) {
      if (newView !== oldView) {
        this.currentPage = 1;
        this.loadData();
        }
    },
    $route(to, from) {
      if (to.query.view !== from.query.view) {
        this.currentView = to.query.view || this.currentView; // Use currentView if no query param
        this.loadData();
      }
    }
  },
  created() {
    const view = this.$route.query.view; // Remove the default value here
    if (view) {
      this.currentView = view;
    } else {
      this.currentView = 'recipes'; // Set to recipes only if no view is specified
    }
    this.loadData();
    this.categorizeItems();
    this.categorizeStorageItems();
    
    this.checkAuth();
  },
  methods: {
    async handleViewChange(view) {
      this.searchQueries = [];
      this.currentView = view;
      this.currentPage = 1;
      await this.loadData();
      this.filterItems();
    },
    handleBadgeFilter(filter) {
      const index = this.selectedExpirationFilters.indexOf(filter);

      if (index !== -1) {
       
        this.selectedExpirationFilters.splice(index, 1);
      } else {
        
        this.selectedExpirationFilters.push(filter);
      }
      this.selectedExpirationFilters = [...this.selectedExpirationFilters];

      this.filterItems();
      
    },
    async handleListItemAdded(newItem) {
      this.listItems.unshift(newItem);
     
      cache.listItems = this.listItems;
      this.categorizeItems();
      await this.loadListItems();
      this.filterItems();
    },
    categorizeItems() {
      this.sortedItems = {
        "ObstundGemüse": [],
        "Vorratsschrank": [],
        "Kühlschrank": [],
        "Süßigkeiten": [],
        "Getränke": [],
        "Haushalt": [],
        "Gefrierschrank": [],
        "Sonstiges": []
      };
      
      this.listItems.forEach(item => {
        let found = false;
        if (item.title.toLowerCase().startsWith("tk")) {
          this.sortedItems["Gefrierschrank"].push(item);
          found = true;
        } else {
          for (const category in this.categories) {
            const match = this.categories[category].some(keyword => item.title.toLowerCase().includes(keyword.toLowerCase()));
            if (match) {
              this.sortedItems[category].push(item);
              found = true;
              break;
            }
          }
        }
        if (!found) {
          this.sortedItems["Sonstiges"].push(item);
        }
      });

      // Flatten sortedItems into listItems to include all items across categories
      this.listItems = Object.values(this.sortedItems).flat();
    },
    categorizeStorageItems() {

      this.sortedStorageItems = {
        "ObstundGemüse": [],
        "Vorratsschrank": [],
        "Kühlschrank": [],
        "Süßigkeiten": [],
        "Getränke": [],
        "Haushalt": [],
        "Gefrierschrank": [],
        "Sonstiges": []
      };

      this.paginatedItems.forEach(item => {
        let found = false;
        if (item.title.toLowerCase().startsWith("tk")) {
          this.sortedStorageItems["Gefrierschrank"].push(item);
          found = true;
        } else {
          for (const category in this.categories) {
            const match = this.categories[category].some(keyword => item.title.toLowerCase().includes(keyword.toLowerCase()));
            if (match) {
              this.sortedStorageItems[category].push(item);
              found = true;
              break;
            }
          }
        }
        if (!found) {
          this.sortedStorageItems["Sonstiges"].push(item);
        }
      });

      this.storageItems = Object.values(this.sortedStorageItems).flat();
    },
    async updateListItem({ oldTitle, updatedItem }) {
      // Find the index of the item using the old title
      const index = this.listItems.findIndex(item => item.title === oldTitle);
      if (index !== -1) {
        // Replace the old item with the updated item (including the new title)
        this.listItems.splice(index, 1, updatedItem);
        cache.listItems = [...this.listItems]; // Update cache with the updated list

        this.categorizeItems();
        // Optionally reload the list items after the update if necessary
        await this.loadListItems();
      }
    },
    async removeListItem(itemTitle) {
      // handle removing item from the list
      this.listItems = this.listItems.filter(item => item.title !== itemTitle);
      cache.listItems = this.listItems;
      this.categorizeItems();
      await this.loadListItems();
      this.filterItems();
    },
    async toDishes(newDish) {
      try {
          // Add the new dish to the beginning of the dishesItems array
          this.dishesItems.unshift(newDish);

          // Update the cache to reflect the new state
          cache.dishesItems = this.dishesItems;

          // Reapply filters and pagination if necessary
          this.filterItems(); 
          this.paginateItems(); 
        } catch (error) {
          console.error('Error adding dish to dishes:', error);
      }
    },

    async removeDishes(dishTitle) {
      try {
        // Find the dish by title and remove it from the dishesItems array
        this.dishesItems = this.dishesItems.filter(item => item.title.toLowerCase() !== dishTitle.toLowerCase());

        // Update the cache to reflect the new state
        cache.dishesItems = this.dishesItems;

        // Reapply filters and pagination if necessary
        this.filterItems();
        this.paginateItems(); 
      } catch (error) {
        console.error('Error removing dish from dishes:', error);
      }
    },
    async updateStorage(item) {
      try {
        // Find the item in the local storageItems array
        const existingIndex = this.storageItems.findIndex(
          storageItem => storageItem.title.toLowerCase() === item.title.toLowerCase()
        );
        if (existingIndex !== -1) {
          // If the item already exists in storage, update its quantity
          this.storageItems[existingIndex].quantity += item.quantity;
        } else {
          // If it's a new item, add it to the beginning of the storageItems array
          this.storageItems = [item, ...this.storageItems]; // Spread existing items to ensure all items remain in the array
        }

        // Update the cache to reflect the new state
        cache.storageItems = [...this.storageItems]; // Ensure the cache is also updated with the complete array
        
        
        // Optional: Emit an event or update the UI as needed
        //this.filterItems(); // Reapply filters if necessary
        this.loadData();

      } catch (error) {
        console.error('Error updating storage:', error);
      }
    },
    async loadListItems() {
      this.itemsPerPage = 200;
      try {
        if (cache.listItems) {
          this.listItems = cache.listItems;
          this.filteredItems = this.listItems;
          return;
        }

        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.get(`/api/listItem/?id=${username}`);
        if (response.data && Array.isArray(response.data)) {
          
          this.listItems = response.data.reverse();
          cache.listItems = this.listItems;
          this.filteredItems = this.listItems;
        } else {
          console.error('Error: Expected an array of list items', response.data);
        }
        this.categorizeItems();
      } catch (error) {
        console.error('Error loading list items:', error);
      }
    },
    countAvailableIngredients(ingredients) {
      if (!ingredients) return 0;

      let count = 0;
      
      ingredients.forEach(ingredient => {
        const foundInStorage = this.storageItems.find(storageItem => {
          return (
            storageItem.title.toLowerCase().trim() === ingredient.name.toLowerCase().trim() &&
            storageItem.quantity >= parseFloat(ingredient.amount)
          );
        });

        if (foundInStorage) {
          count++;
        }
      });

      return count;
    },
    showAddStorageModal() {
     
      this.showAddStorageModalFlag = true;
    },
    showAddDishModal() {
      this.editableDishData = {
        title: '',
        ingredients: [{ amount: '1', unit: 'Pck', name: '' }],
        mealType: ''
      };
      this.showAddDishModalFlag = true;
    },
    changeView(view) {
      this.currentView = view;
      this.$router.push({ path: '/', query: { view: this.currentView } }); 
      this.loadData(); 
    },
    setView(view) {
      this.currentView = view;
      this.currentPage = 1;
      this.loadData(); // Assuming you have this method to load data based on the current view
      this.$router.push({ path: '/', query: { view: this.currentView } }); // Update URL if needed
    },
    checkAuth() {
      const token = getCookie('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken) {
          this.isAuthenticated = true;
        }
      }
    },
    async addDish(newDish) {
      newDish.amount = 1;

      // Combine amount and unit into a single string in the amount field
      newDish.ingredients = newDish.ingredients.map(ingredient => {
        // Combine the amount and unit into a single string
        ingredient.amount = `${ingredient.amount} ${ingredient.unit}`;

        // Remove the unit key from the ingredient object
        delete ingredient.unit;

        return ingredient;
      });


      // Push the new dish to the dishesItems array
      this.dishesItems.unshift(newDish); 

      cache.dishesItems = this.dishesItems; // Update the cache
      this.handleViewChange('dishes');

      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      axios.post(`/api/dishesItem/?id=${username}`, newDish)
        .then(response => {
          console.log('Dish added successfully:', response.data);
        })
        .catch(error => {
          console.error('Error adding dish:', error);
        });
    },
    applyFilter(selectedTags) {
      this.selectedTags = selectedTags;
      this.filterItems();
      this.currentPage = 1;
    },
    async applySearch(searchQueries) {

      this.searchQueries = searchQueries.map(query => query.toLowerCase()); 
      this.currentPage = 1;

      await this.loadData();
    },
    applySort(sortOption) {
      this.selectedSort = sortOption;
      this.sortItems();
    
      this.currentPage = 1;
    },
    async updateDishes(updatedDishes) {
      this.dishesItems = updatedDishes;

      cache.dishesItems = this.dishesItems;
      this.filterItems();
      await this.loadDishesItems();
    },
    sortItems() {
      if (this.selectedSort === 'newest') {
        this.filteredItems.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
      } else if (this.selectedSort === 'expirationAsc') {
        this.filteredItems.sort((a, b) => new Date(a.expiration) - new Date(b.expiration));
      } else if (this.selectedSort === 'expirationDesc') {
        this.filteredItems.sort((a, b) => new Date(b.expiration) - new Date(a.expiration));
      } else if (this.selectedSort === 'nameAsc') {
        this.filteredItems.sort((a, b) => a.title.localeCompare(b.title));
      } else if (this.selectedSort === 'nameDesc') {
        this.filteredItems.sort((a, b) => b.title.localeCompare(a.title));
      } else if (this.currentView === 'storage' && this.selectedSort === 'quantityAsc') {
        this.filteredItems.sort((a, b) => a.quantity - b.quantity);
      } else if (this.currentView === 'storage' && this.selectedSort === 'quantityDesc') {
        this.filteredItems.sort((a, b) => b.quantity - a.quantity);
      } else if (this.currentView === 'dishes' && this.selectedSort === 'quantityAsc') {
        this.filteredItems.sort((a, b) => a.amount - b.amount);
      } else if (this.currentView === 'dishes' && this.selectedSort === 'quantityDesc') {
        this.filteredItems.sort((a, b) => b.amount - a.amount);
      } else if (this.currentView === 'dishes' && this.selectedSort === 'ingredientsDesc') {
        this.filteredItems.sort((a, b) => {
          const aIngredientsAvailable = this.countAvailableIngredients(a.ingredients);
          const bIngredientsAvailable = this.countAvailableIngredients(b.ingredients);

          return bIngredientsAvailable - aIngredientsAvailable; // Sort descending
        });
      } 
    },
    filterItems() {
      let filtered;

      if (this.currentView === 'recipes') {
        filtered = this.items;
      } else if (this.currentView === 'storage') {

        if (this.selectedExpirationFilters.length == 0 || this.selectedExpirationFilters.length > 1 ) {
          filtered = cache.storageItems;
        }
        else
        {
          filtered = this.storageItems;
        }
        //filtered = this.storageItems.filter(item => item && item.title && item.quantity && item.quantityUnit); 
      } else if (this.currentView === 'dishes') {
        filtered = this.dishesItems.filter(dish => dish && dish.title); 
      } else {
        filtered = []; // Default case to avoid undefined issues
      }

      if (this.selectedTags.length > 0) {
        filtered = filtered.filter(item => {
          const itemTags = item.tags || [];
          const itemMethods = item.method || [];
          return this.selectedTags.every(tag => itemTags.includes(tag) || itemMethods.includes(tag));
        });
      }

      if (this.searchQueries.length > 0) {
        filtered = filtered.filter(item => {
          const itemTitle = item.title ? item.title.toLowerCase() : '';
          const itemIngredients = item.ingredients ? item.ingredients.map(ing => ing.name.toLowerCase()) : [];
          const itemMealType = item.mealType ? item.mealType.toLowerCase() : '';
          const username = item.username ? item.username.toLowerCase() : '';  // Add username to search

          return this.searchQueries.some(query => {
            return itemTitle.includes(query) ||
                  itemIngredients.some(ingredient => ingredient.includes(query)) ||
                  itemMealType.includes(query) ||
                  username.includes(query);  // Now search for username
          });
        });
        
      }

      if (this.currentView === 'storage' && this.selectedExpirationFilters.length > 0) {
        const today = new Date();

        filtered = filtered.filter(item => {
            if (!item.expiration || item.expiration === 'N/A') return false;

            const expirationDate = new Date(item.expiration);
            const diffDays = Math.ceil((expirationDate - today) / (1000 * 60 * 60 * 24));

            return this.selectedExpirationFilters.some(filter => {
            switch (filter) {
              case 'expired':
                return expirationDate < today;
              case 'critical':
                return diffDays <= 4 && diffDays > 0;
              case 'warning':
                return diffDays <= 14 && diffDays > 4;
              case 'good':
                return diffDays > 14;
              default:
                return false;
            }
            });
          });
        }

      //this.storageItems = filtered;
      if(filtered !== undefined)
      {
       console.log("filtered  " + filtered.length);
        this.filteredItems = filtered;
        this.sortItems(); // Ensure items are sorted after filtering
        this.paginateItems();
 
        this.categorizeStorageItems();
      }
  },
  paginateItems() {


    if(this.filteredItems.length <1)
    {
      this.paginatedItems = []; 
      return;
    }


    if (!Array.isArray(this.filteredItems) || this.filteredItems === undefined || this.filteredItems === null || this.filteredItems.length<1 ) {
        this.paginatedItems = []; 
        return;
      }


      if(this.currentPage == 0)
      {
        this.currentPage = 1;
      }
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      this.paginatedItems = this.filteredItems?.slice(start, end) || [];

        
  },
    changePage(page) {
      this.currentPage = page;
    },
    async handleStorageItemAdded(newItem){
      this.storageItems.unshift(newItem);

      cache.storageItems = this.storageItems;
      this.categorizeStorageItems();
      this.handleViewChange('storage')
    },
    handleRecipeItemAdded(){
      cache.recipes = this.recipes;
    },
    async updateItem({ oldTitle, updatedItem }){
      if(this.currentView === 'storage')
      {
        // Convert oldTitle to a string and normalize it
      const normalizedOldTitle = String(oldTitle).trim().toLowerCase();

      // Find the index using the old title
      const index = this.storageItems.findIndex(item => {
        // Convert item.title to a string and normalize it
        const normalizedItemTitle = String(item.title).trim().toLowerCase();
        return normalizedItemTitle === normalizedOldTitle;
      });

        if (index !== -1) {
          // Create a new array with the updated item to avoid mutating the original state
          this.storageItems = [
            ...this.storageItems.slice(0, index),
            updatedItem,
            ...this.storageItems.slice(index + 1)
          ];
          cache.storageItems = this.storageItems;
          this.categorizeStorageItems();
          await this.loadData();
          this.filterItems();
          this.paginateItems();
          //this.loadStorageItems();
        }
      }
    },
    async removeItem(itemEan) {
      if(this.currentView === 'storage')
      {
        this.storageItems = this.storageItems.filter(item => item.title !== itemEan);
        cache.storageItems = this.storageItems;
        this.filterItems();
        this.categorizeStorageItems();
        
        //await this.loadStorageItems();
      }
      else if(this.currentView === 'dishes')
      {
        this.dishesItems = this.dishesItems.filter(item => item.title !== itemEan);
        cache.dishesItems = this.dishesItems;
        this.filterItems();
        await this.loadStorageItems();
        
      }
    },
    editDish(dish) {
    this.editableDishData = {
      ...dish,
      ingredients: dish.ingredients.map(ingredient => {
        // Assuming amount contains both the numeric value and the unit (e.g., "500 g")
       
        let amount = '';
        let unit = '';

        // Check if ingredient already has a separate unit
        if (ingredient.unit !== undefined) {
          amount = ingredient.amount;  // Use the provided amount
          unit = ingredient.unit;      // Use the provided unit
        } else {
          // If no unit is provided, split the amount (assuming it's in "amount unit" format)
          const amountParts = ingredient.amount.split(" ");
          amount = amountParts[0];    // The numeric part
          unit = amountParts[1] || 'g'; // The unit part, default to 'g' if undefined
        }
        return { ...ingredient, amount, unit };  // Split into amount and unit
      })
    };

    this.showAddDishModalFlag = true;
  },

    // Update the dish after it's edited in the modal
    async updateDish({ originalTitle, updatedDish }) {
      const token = getCookie('token');
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      // PUT request to update the dish
      await axios.put(`/api/dishesItem/?userid=${username}&title=${originalTitle}`, updatedDish);

      const normalizedOriginalTitle = originalTitle.toLowerCase().trim();
      // Find the index using the normalized title
      const index = this.dishesItems.findIndex(dish => dish.title.toLowerCase().trim() === normalizedOriginalTitle);

      if (index !== -1) {
        // Directly replace the item at that index
        this.dishesItems[index] = updatedDish;  // Replace the dish with the updated one
      } else {
        console.error(`Dish with title "${originalTitle}" not found`);
      }
       
      ///this.$set(this.dishesItems, index, updatedDish);
      //cache.dishesItems = [...this.dishesItems]; 
      cache.dishesItems = this.dishesItems;
      this.handleViewChange('dishes');
      this.showAddDishModalFlag = false; // Close modal after editing
    },
    async updateList() {
      cache.listItems = null;
      this.loadData;
    },
    async loadData() {
      if (this.currentView === 'recipes') {
        await this.loadRecipes();  
      } else if (this.currentView === 'storage') {
        await this.loadStorageItems();  
        this.categorizeStorageItems(); 
      } else if (this.currentView === 'dishes') {
        await this.loadDishesItems();  
      } else if (this.currentView === 'list') {
        await this.loadListItems();  
      }

      // Ensure that filteredItems are updated after loading the data
      this.filterItems();

    },
    async toggleView() {
      this.currentView = this.isStorageView ? 'storage' : 'recipes';
      this.loadData();
      this.$router.push({ path: '/', query: { view: this.currentView } });
      this.currentPage = 1;
    },
    async loadRecipes() {
      this.itemsPerPage = 9;
        try {
          // Check cache first
          if (cache.recipes) {
            this.items = cache.recipes;
            this.filteredItems = this.items;
            return;
          }

          // Fetch data from API
          const response = await axios.get('/api/recipes');
          const recipes = response.data.reverse();

          // Fetch reviews and calculate evaluation for each recipe
          const itemsWithEvaluation = await Promise.all(recipes.map(async item => {
            // Check cache for reviews
            
            if (!item || !item.id) {
              console.warn('Encountered a null or invalid item:', item);
              return null; // Skip null or invalid items
            }

            let reviews = cache.reviews[item.id];
            if (!reviews) {
              const reviewsResponse = await axios.get(`/api/reviews?id=${item.id}`);
              reviews = reviewsResponse.data;
              cache.reviews[item.id] = reviews; // Store reviews in cache
            }
            const evaluation = reviews.length > 0 ? (reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length) : 0;
            
            
            return {
              ...item,
              tags: item.tags != null ? item.tags : [], // Ensure tags is an array
              method: item.method != null ? item.method : [], // Ensure method is an array
              images: item.images != null ? item.images : [], // Ensure images is an array
              image: item.images && item.images.length > 0 ? item.images[0] : '', // Use the first image if available
              username: item.username,
              evaluation: evaluation
            };
          }));

          const validItems = itemsWithEvaluation.filter(item => item !== null);


          // Sort valid items by creation date in descending order
          this.items = validItems.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));


         // this.items = itemsWithEvaluation.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate)); // Sort by creation date in descending order
          this.filteredItems = this.items;

          // Cache the recipes
          cache.recipes = this.items;
        } catch (error) {
          console.error('Error loading recipes:', error);
        }
    },
    async loadStorageItems() {
      this.itemsPerPage = 200;
      try {

        if (cache.storageItems) {
          console.log("cache.storageItems " +cache.storageItems.length);
          this.storageItems = cache.storageItems;
          this.filteredItems = this.storageItems; // If you're displaying storage items
          this.paginateItems();
          this.categorizeStorageItems();
          return;
        }

        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.get(`/api/storageItem/?id=${username}`);
        if (response.data && Array.isArray(response.data)) {
          const items = response.data.reverse();
          this.storageItems = items.map(item => ({
            ...item,
            tags: item.tags != null ? item.tags : [],
            expiration: item.expiration || ''
          }));

          cache.storageItems = this.storageItems;
          this.filteredItems = this.storageItems;
          
        } else {
          console.error('Error: Expected an array of storage items', response.data);
        }
      } catch (error) {
        console.error('Error loading storage items:', error);
      }
    },
   
    async loadDishesItems() {
    try {
      if (cache.dishesItems) {
        this.dishesItems = cache.dishesItems.filter(dish => dish && dish.title);
        this.filteredItems = this.dishesItems;
        return;
      }

      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      const response = await axios.get(`/api/dishesItem/?id=${username}`);
      if (response.data && Array.isArray(response.data)) {
        this.dishesItems = response.data
          .reverse()
          .filter(dish => dish && dish.title); // Filter out empty items

        
        cache.dishesItems = this.dishesItems;
        this.filteredItems = this.dishesItems;

      } else {
        console.error('Error: Expected an array of dishes items', response.data);
      }
    } catch (error) {
      console.error('Error loading dishes items:', error);
    }
  }
  }
}
</script>

<style scoped>
.toggle-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.category-header {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: #b0b0b0; /* Light grey text */
  margin: 20px 0;
  font-size: 16px;
  width: 100%; /* Make the header as wide as the content */
}

.category-header::before,
.category-header::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #d0d0d0; /* Light grey line */
  margin: 0 10px; /* Space between text and line */
}

.category-header span {
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
  color: #b0b0b0; /* Subtle text color */
}

.category-header hr {
  border: none;
  border-top: 1px solid gray;
  width: 100%;
}

.category-header h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.custom-switch {
  display: flex;
  align-items: center
}

.form-switch{
  padding-left: 0 !important;
}

.switch-container {
  display: flex;
  align-items: center;
}

.custom-switch .form-check-input {
  background-color: #ac8d7e;
  border-color: #ac8d7e;
  margin: 0 10px;
}

.custom-switch .form-check-input:checked {
  background-color: #ac8d7e;
  border-color: #ac8d7e;
}

.form-check-label.left-label {
  margin-right: 10px;
}

.form-check-label.right-label {
  margin-left: 10px;
}
.view-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-button {
  flex: 1;
  padding: 8px 15px;
  margin: 0 5px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 14px;
}

.toggle-button.active {
  background-color: #ac8d7e;
  color: white;
  border: 1px solid #ac8d7e;
  font-weight: bold;
}

.toggle-button:not(.active):hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .toggle-buttons {
    flex-direction: column;
  }
  
  .toggle-button {
    margin-bottom: 10px;
  }
}
.tab-navigation {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.tab-navigation button {
  flex: 1;
  padding: 10px 20px;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s ease;
  color: black;
}

.tab-navigation button.active {
  border-bottom-color: #ac8d7e;
  font-weight: bold;
  color: black;
}

.tab-navigation button:hover:not(.active) {
  background-color: #f9f9f9;
}

.row{
  padding: 0 !important;
}

.list-icon{
  font-size: 24px; 
  padding: 5px;
}

.badge-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
}

.badge {
  display: inline-block;
  padding: 0.4em 0.8em;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 0.5rem;
  text-align: center;
  color: #857d7d;
}

.expired-badge {
  background-color: #f8d7da; /* Matches the light red for expired */
}

.critical-badge {
  background-color: #f4cb9d; /* Dark orange for < 4 days */
}

.warning-badge {
  background-color: rgb(245, 222, 180); /* Light orange for < 2 weeks */
}

.good-badge {
  background-color: #d4edda; /* Matches the green for > 2 weeks */
}

.enough-badge{
  background-color: lightgreen;
}
.different-unit-badge{
  background-color: #d4ff80;
}
.not-enough-badge{
  background-color: #FFD580;
}

.badge.selected-badge {
  border: 2px solid #ac8d7e; /* Highlight the selected badge */
  font-weight: bold;
  color: #4a4a4a;
}

</style>