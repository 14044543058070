<template>
  <div class="modal-overlay">
      <div class="modal-content">
          <div class="modal-header">
              <h2>{{ dishData && dishData.title ? 'Gericht bearbeiten' : 'Neues Gericht hinzufügen' }}</h2>
              <button class="modal-close" @click="closeModal">×</button>
          </div>

          <div class="modal-body">
              <form @submit.prevent="submitDish">
                  <div class="form-group">
                      <label for="dish-title">Gericht Name</label>
                      <input 
                          type="text" 
                          id="dish-title" 
                          v-model="dish.title" 
                          @input="checkDishTitle" 
                          class="form-control" 
                          required 
                          placeholder="Titel eingeben..." 
                      />
                      <div v-if="titleExists === true" class="text-danger">
                          <i class="bi bi-x-circle-fill"></i> Dieses Gericht existiert bereits
                      </div>
                      <div v-if="titleExists === false" class="text-success">
                          <i class="bi bi-check-circle-fill"></i> Gericht noch nicht in der Liste
                      </div>
                  </div>

                  <div class="form-group">
                      <label for="meal-type">Art des Gerichts</label>
                      <select v-model="dish.mealType" id="meal-type" class="form-control">
                          <option value="" disabled selected>Wählen Sie eine Option</option>
                          <option value="Frühstück">Frühstück</option>
                          <option value="Mittagessen">Mittagessen</option>
                          <option value="Abendessen">Abendessen</option>
                          <option value="Dessert">Dessert</option>
                          <option value="Snack">Snack</option>
                      </select>
                  </div>

                  <div class="form-group">
                      <div class="d-flex justify-content-between">
                          <label>Zutaten</label>
                          <button type="button" @click="addIngredient" class="btn btn-primary add-ingredient-button">+</button>
                      </div>
                      <div v-for="(ingredient, index) in dish.ingredients" :key="index" class="ingredient-row">
                          <button type="button" @click="removeIngredient(index)" class="remove-ingredient-button">-</button>
                          <input 
                              type="number" 
                              v-model="ingredient.amount" 
                              class="form-control amount-input w-20" 
                              placeholder="Menge" 
                              @input="validateAmountInput"
                          />
                          <select v-model="ingredient.unit" class="form-control unit-select">
                            <option value="g">g</option>
                            <option value="kg">kg</option>
                            <option value="l">l</option>
                            <option value="ml">ml</option>
                            <option value="EL">EL</option>
                            <option value="TL">TL</option>
                            <option value="Pck">Pck</option>
                            <option value="Stck">Stck</option>
                            <option value="Prise">Prise/n</option>
                            <option value="Scheibe/n">Scheiben</option>
                          </select>
                          <input 
                              type="text" 
                              v-model="ingredient.name" 
                              class="form-control ingredient-input" 
                              placeholder="Zutat" 
                              required 
                          />
                      </div>
                  </div>

                  <div class="form-actions">
                      <button type="button" class="btn btn-secondary" @click="closeModal">Abbrechen</button>
                      <button type="submit" class="btn btn-primary">{{ dish.title ? 'Speichern' : 'Gericht hinzufügen' }}</button>
                  </div>
              </form>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';

export default {
  props: {
      // Editable dish data passed down from the parent (optional)
      dishData: {
          type: Object,
          default: () => ({
              title: '',
              ingredients: [{ amount: 1, unit: 'Pck', name: '' }],
              mealType: ''
          })
      }
  },
  data() {
      return {
          dish: JSON.parse(JSON.stringify(this.dishData)), // Clone the dishData to avoid mutating the original object
          titleExists: null,
          originalTitle: this.dishData.title
      };
  },
  methods: {
      addIngredient() {
          this.dish.ingredients.push({ amount: '1', unit: 'Pck', name: '' });
      },
      removeIngredient(index) {
          this.dish.ingredients.splice(index, 1);
      },
      closeModal() {
          this.$emit('close-modal');
      },
      validateAmountInput(event) {
          const value = event.target.value;
          event.target.value = value.replace(/[^0-9]/g, '');
          if (event.target.value < 0) {
              event.target.value = '';
          }
      },
      async checkDishTitle() {
          const token = getCookie('token');
          if (!token) {
              throw new Error('Token not found');
          }
          const decodedToken = jwtDecode(token);
          const username = decodedToken.username;

          const titleToCheck = this.dish.title.toLowerCase().trim();

          if (titleToCheck === '') {
              this.titleExists = null;
              return;
          }

          try {
              const response = await axios.get(`/api/checkDishesTitle?userid=${username}&title=${encodeURIComponent(titleToCheck)}`);
              this.titleExists = response.data.exists;
          } catch (error) {
              console.error('Error checking dish title:', error);
              this.titleExists = null;
          }
      },
      submitDish() {
        // Check if we're in add mode by checking if the dishData has a title or not
        if (this.originalTitle && this.originalTitle !== "") {
            // If originalTitle exists, it's an update
            this.$emit('dish-updated', { originalTitle: this.originalTitle, updatedDish: this.dish });
        } else if (this.dish.title && this.titleExists === false) {
 
            this.$emit('dish-added', this.dish);
        } else if (this.titleExists === true) {
            // Title already exists
            console.log("Dish with this title already exists");
        }

        // Close the modal regardless of what happens
        this.closeModal();
    }
  }
};
</script>

<style scoped>
/* The same styles as provided earlier */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

.ingredient-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.amount-input{
  width: 25%;
}

.unit-select{
  flex: 30%;
}


.remove-ingredient-button {
  background-color: #ac8d7e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.remove-ingredient-button:hover {
  background-color: #ac8d7e;
}

.add-ingredient-button {
  background-color: #ac8d7e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
}

.add-ingredient-button:hover {
  background-color: #ac8d7e;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-primary {
  background-color: #ac8d7e !important;
  color: white !important;
}

.btn-primary:hover {
  background-color: #ac8d7e;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #5a6268;
}
</style>