<template>
  <div class="modal-overlay" v-if="showModal">
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ activeTab === 'agb' ? 'AGB' : 'Datenschutzerklärung' }}</h2>
        <button class="modal-close" @click="cancel">×</button>
      </div>
      <div class="modal-tabs">
        <button
          :class="{ active: activeTab === 'agb' }"
          @click="activeTab = 'agb'"
        >
          AGB
        </button>
        <button
          :class="{ active: activeTab === 'datenschutz' }"
          @click="activeTab = 'datenschutz'"
        >
          Datenschutzerklärung
        </button>
      </div>
      <div class="modal-body">
        <iframe
          v-if="activeTab === 'agb'"
          :src="srcWithNoHeader('/agb')"
          frameborder="0"
          class="iframe-content"
        ></iframe>
        <iframe
          v-if="activeTab === 'datenschutz'"
          :src="srcWithNoHeader('/datenschutz')"
          frameborder="0"
          class="iframe-content"
        ></iframe>
      </div>
      <div class="modal-footer">
        <div class="consent-checkbox">
          <input
            type="checkbox"
            id="acceptConsent"
            v-model="consentAccepted"
          />
          <label for="acceptConsent">
            Ich akzeptiere hiermit die AGB und Datenschutzerklärung
          </label>
        </div>
        <button :disabled="!consentAccepted" @click="acceptConsent" class="btn-style">
          Alles Akzeptieren
        </button>
        <button @click="cancel" class="btn-style">
          Abbrechen
        </button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeTab: "agb",
      consentAccepted: false,
    };
  },
  methods: {
    acceptConsent() {
      this.$emit("accept-consent");
    },
    cancel() {
      this.$emit("cancel");
    },
    srcWithNoHeader(url) {
      return `${url}?noheader=true`; // Assuming your backend can handle this query parameter to hide header and footer
    },
  },
};
</script>
  
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears on top of other content */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 90%;
  height: 80vh; /* Increase height for better visibility */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  flex-grow: 1;
  overflow-y: auto;
}

.iframe-content {
  width: 100%;
  height: 60vh; /* Height of the iframe to take up most of the modal */
  border: none;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.modal-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modal-tabs button {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  background-color: #e9dfd4;
  border: 1px solid #ddd;
  border-bottom: none;
  transition: background-color 0.3s ease;
}

.modal-tabs button.active {
  background-color: #ac8d7e;
  color: white;
  border-bottom: 2px solid #ac8d7e;
  font-weight: bold;
}

.modal-tabs button:not(.active):hover {
  background-color: #e0e0e0;
}

.modal-tabs .tab-btn.active {
  background-color: #ac8d7e; /* Same color as your button */
  color: white;
  border-bottom: 2px solid #ac8d7e;
  font-weight: bold;
}

.consent-checkbox {
  display: flex;
  align-items: center;
}

.consent-checkbox label {
  margin-left: 10px;
}

.btn-style {
  background-color: #ac8d7e;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-style:disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .modal-content {
    max-height: 80%;
    width: 95%;
  }

  .iframe-content {
    height: 50vh;
  }
}

@media all and (display-mode: fullscreen) {
  header, footer {
    display: none;
  }
  body {
    padding-top: 0;
  }
}
</style>