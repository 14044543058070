<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="title-bar">
        <h2>Zum Vorrat Hinzufügen</h2>
        <button class="btn btn-outline-secondary camera-button" @click="toggleScanner">
          <i class="bi bi-camera"></i>
        </button>
      </div>

      <div v-if="showScanner" class="scanner-frame">
        <div class="scanner-container">
          <StreamBarcodeReader @decode="onDetected" @loaded="onLoaded"></StreamBarcodeReader>
        </div>
        <button class="btn btn-outline-secondary scanner-close" @click="toggleScanner">Scanner schließen</button>
      </div>

      <form @submit.prevent="addItem">
        <div class="form-group">
          <label for="ean">EAN Code</label>
          <input type="text" v-model="ean" id="ean" class="form-control" @blur="fetchProductInfo"/>
        </div>
        <div class="form-group">
          <label for="name">Objekt Name</label>
          <input 
            type="text" 
            v-model="name" 
            id="name"   
            @input="checkStorageTitle"  
            required 
            class="form-control" 
          />
          <div v-if="titleExists === true" class="text-danger">
              <i class="bi bi-x-circle-fill"></i> Dieses Objekt existiert bereits
          </div>
          <div v-if="titleExists === false" class="text-success">
              <i class="bi bi-check-circle-fill"></i> Objekt noch nicht in der Liste
          </div>
        </div>
        <div class="form-group">
          <label for="expiration">Ablaufdatum</label>
          <input 
            type="date" 
            v-model="expiration" 
            id="expiration" 
            class="form-control" 
          />
        </div>
        <div class="form-group">
          <label for="tags">Tags</label>
          <input type="text" v-model="tags" id="tags" class="form-control" placeholder="Comma separated"/>
        </div>
        <div class="form-group quantity-group">
          <div class="quantity-input">
            <label for="quantity">Menge</label>
            <input type="number" v-model="quantity" id="quantity" class="form-control" />
          </div>
          <div class="quantity-select">
            <label for="quantityUnit">Einheit</label>
            <select v-model="quantityUnit" id="quantityUnit" class="form-control">
              <option value="g">g</option>
              <option value="kg">kg</option>
              <option value="l">l</option>
              <option value="ml">ml</option>
              <option value="EL">EL</option>
              <option value="TL">TL</option>
              <option value="Pck">Pck</option>
              <option value="Stck">Stck</option>
            </select>
          </div>
        </div>
        <div class="form-actions">
          <button type="button" class="btn btn-secondary" @click="closeModal">Fertig</button>
          <button type="submit" class="btn btn-primary" :disabled="titleExists === true">Item hinzufügen</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { StreamBarcodeReader } from 'vue-barcode-reader';
import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';

export default {
  name: 'AddStorageItem',
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      ean: '',
      name: '',
      expiration: '',
      tags: '',
      quantity: 1,
      quantityUnit: 'Pck',
      showScanner: false,
      titleExists: null,
    };
  },
  mounted() {

    this.$watch('quantity', (newVal) => {
      if (newVal < 0) {
        this.quantity = 1; // Reset to 1 if value is set to zero or less
      }
    });
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    async fetchProductInfo() {
      if (this.ean) {
        try {
          const response = await axios.get(`https://world.openfoodfacts.org/api/v0/product/${this.ean}.json`);
          if (response.data.status === 1) {
            const product = response.data.product;
            const productName = product.product_name_de || product.product_name || '';
            const brandsTags = product.brands_tags || [];
            this.tags = brandsTags.join(', ');
            this.name = productName;
          } else {
            alert('Product konnte nicht gefunden werden');
          }
        } catch (error) {
          console.error('Error fetching product info:', error);
        }
      }
    },
    async checkStorageTitle() {
      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      const titleToCheck = this.name.toLowerCase().trim();

      if (titleToCheck === '') {
        this.titleExists = null;
        return;
      }

      try {
        const response = await axios.get(`/api/checkStorageTitle?userid=${username}&title=${encodeURIComponent(titleToCheck)}`);
        this.titleExists = response.data.exists;
      } catch (error) {
        console.error('Error checking dish title:', error);
        this.titleExists = null;
      }
    },
    async addItem() {
      const itemData = {
        ean: this.ean,
        title: this.name,
        expiration: this.expiration,
        tags: this.tags.split(',').map((tag) => tag.trim()),
        quantity: this.quantity,
        quantityUnit: this.quantityUnit,
      };
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.post(`/api/storageItem/?id=${username}`, itemData);
        if (response.status === 200) {
          this.$emit('itemAdded', itemData);
          alert('Objekt erfolgreich hinzugefügt');
          this.resetForm();
        }
      } catch (error) {
        console.error('Error adding item:', error);
      }
    },
    resetForm() {
      this.ean = '';
      this.name = '';
      this.expiration = '';
      this.tags = '';
      this.quantity = 1;
      this.quantityUnit = 'Pck';
    },
    toggleScanner() {
      this.showScanner = !this.showScanner;
    },
    onLoaded() {
      //console.log('Scanner loaded and ready');
    },
    onDetected(result) {
      if (result) {
        this.ean = result;
        this.fetchProductInfo();
        this.toggleScanner();
      }
    },
  },
};
</script>

<style scoped>
.add-item-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.camera-button {
  margin-left: 10px;
}

.scanner-frame {
  position: relative;
  margin-bottom: 15px;
  width: 100%;
}

.scanner-container {
  width: 100%;
  max-width: 100%;
  height: auto;
  aspect-ratio: 4/3;
  background: #f0f0f0;
  border: 2px solid #ced4da;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.scanner {
  width: 100%;
  height: auto;
}

.scanner-close {
  margin-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.quantity-group {
  display: flex;
  justify-content: space-between;
}

.quantity-input,
.quantity-select {
  flex: 1;
}

.quantity-select {
  margin-left: 10px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.btn-primary {
  background-color: #ac8d7e;
  border: none;
  padding: 10px 15px;
  color: white;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #ac8d7e;
}

.btn-secondary {
  background-color: #6c757d;
  border: none;
  padding: 10px 15px;
  color: white;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears on top of other content */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 90%;
  height: auto; /* Adjust height for better visibility */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
}

</style>