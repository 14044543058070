<template>
  <div id="app">
    <div v-if="!isWebUserAuthenticated" class="auth-container">
      <div class="auth-modal">
        <h3>Bitte Einloggen</h3>
        <p>Dies ist eine private Webseite</p>
        <form @submit.prevent="checkWebUserPassword">
          <div class="form-group">
            <label for="webUserPassword">Passwort:</label>
            <input type="password" v-model="webUserPassword" id="webUserPassword" class="form-control" required />
          </div>
          <button type="submit" class="btn btn-primary btn-style">Login</button>
        </form>
        <div v-if="authError" class="text-danger mt-2">Falsches Passwort. Bitte nochmal versuchen.</div>
      </div>
    </div>
    <div v-else>
        <nav class="navbar navbar-expand-lg navbar-light navbar-custom">
          <div class="container-fluid justify-content-between">
            <!--<router-link class="navbar-brand" to="/">meisterchef.de</router-link>-->
            <a href="/">
              <img src="/logo.png" alt="meisterchef.de" class="navbar-logo">
            </a>
            <div class="ms-auto">
              <router-link v-if="isAuthenticated" to="/mine" class="btn btn-icon">
                <i class="bi bi-person person-icon"></i>
              </router-link>
              <router-link v-if="!isAuthenticated" class="btn btn-custom" to="/login">Login</router-link>
              <button v-if="isAuthenticated" @click="handleLogout" class="btn btn-custom">Logout</button>
            </div>
          </div>
        </nav>
        
        <router-view></router-view>

        <footer class="footer-custom">
          <div class="container text-center">
            <p>2024 von meisterchef.de</p>
            <div class="footer-links d-flex flex-column flex-md-row justify-content-center">
              <router-link to="/impressum" class="footer-link">Impressum</router-link>
              <router-link to="/datenschutz" class="footer-link">Datenschutzerklärung</router-link>
              <router-link to="/agb" class="footer-link">AGB</router-link>
              <CookiesIndicator />
            </div>
          </div>
        </footer>
        
        <!--<NoCookiesIndicator class="no-cookies-indicator" />-->
        
        <!--<div class="plus-button-container">
          <div v-if="showAddOptions" class="add-options">
            <button v-if="isHomePage" @click="goToAddRecipe" class="btn btn-secondary toggle-btn">Rezept erstellen</button>
          </div>
          <button v-if="isHomePage" @click="toggleAddOptions" class="add-button">+</button>
        </div>
        -->
      </div>
    </div>
</template>

<script>
import { computed, ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
//import NoCookiesIndicator from './components/NoCookiesIndicator.vue';
import CookiesIndicator from './components/CookiesIndicator.vue';
import { useAuth } from './store/auth';

export default {
  name: 'App',
  components: {
    //NoCookiesIndicator
    CookiesIndicator
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { isAuthenticated, logout } = useAuth();
    const isAddRecipePage = computed(() => route.path === '/add-recipe');
    const isHomePage = computed(() => route.path === '/');
    const showAddOptions = ref(false);
    
    const handleLogout = () => {
      logout(router);  // Pass the router instance to the logout function
    };

    const goToAddRecipe = () => {
      router.push('/add-recipe');
    };

    const goToAddStorageItem = () => { // Added
      router.push('/add-storage-item');
    };

    const toggleAddOptions = () => { // Added
      showAddOptions.value = !showAddOptions.value;
    };

    const isWebUserAuthenticated = ref(false);
    const webUserPassword = ref('');
    const authError = ref(false);

    const checkWebUserPassword = () => {
      const correctPassword = 'ichbineinmeisterchef'; // Set your password here
      if (webUserPassword.value === correctPassword) {
        isWebUserAuthenticated.value = true;
        authError.value = false;
        localStorage.setItem('webUserAuthenticated', 'true'); // Store the authentication state
      } else {
        authError.value = true;
      }
    };

    onMounted(() => {
      if (localStorage.getItem('webUserAuthenticated') === 'true') {
        isWebUserAuthenticated.value = true;
      }
    });

    watch(isAuthenticated, (newValue) => {
      if (newValue) {
        router.push('/'); // Navigate to the homepage
      }
    });
    
    return { 
      isAuthenticated, 
      isAddRecipePage, 
      logout, 
      handleLogout,
      goToAddRecipe, 
      goToAddStorageItem, // Added
      toggleAddOptions, // Added
      showAddOptions, // Added
      isHomePage, 
      isWebUserAuthenticated, 
      checkWebUserPassword, 
      webUserPassword, 
      authError, 
    };
  }
}
</script>


<style>
@import './assets/custom.css';

.navbar-custom .navbar-text {
  font-size: 1rem;
  color: #000;
}

.navbar-logo {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px; /* Adjust the height as needed */
  width: auto;
  padding-bottom: 2px;
}

.footer-custom {
  background-color: #e9dfd4;
  padding: 20px 0;
  border-top: 1px solid #e9dfd4;
}

.footer-links {
  display: flex;
  flex-direction: column; /* Stack vertically on mobile */
}

.footer-link {
  color: #000;
  margin: 5px 0; /* Add margin for spacing between links */
}

@media (min-width: 768px) {
  .footer-links {
    flex-direction: row; /* Stack horizontally on larger screens */
  }

  .footer-link {
    margin: 0 10px; /* Add horizontal margin for spacing between links */
  }
}

.footer-link:hover {
  text-decoration: underline;
}

#app {
  position: relative;
  z-index: 1; /* Ensure the app content is displayed behind the banner and modal */
}

.no-cookies-indicator {
  position: fixed;
  bottom: 20px;
  left: 20px;
}

.plus-button-container { /* Added */
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.add-button {
  background-color: #ac8d7e;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #ac8d7e;
}

.add-options { /* Added */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}

.add-options .btn { /* Added */
  margin-bottom: 5px;
}

.person-icon {
  font-size: 24px;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.auth-modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.auth-modal .form-group {
  margin-bottom: 20px;
}

.btn-style {
  background-color: #ac8d7e !important;
  border: none !important;
}

.toggle-btn{
  border-color: tan;
  color: #000;
  background-color:#f5f5ef;
}
</style>